/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    camera2: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M5 8c0-1.657 2.343-3 4-3V4a4 4 0 00-4 4"/><path pid="1" d="M12.318 3h2.015C15.253 3 16 3.746 16 4.667v6.666c0 .92-.746 1.667-1.667 1.667h-2.015A5.97 5.97 0 019 14a5.97 5.97 0 01-3.318-1H1.667C.747 13 0 12.254 0 11.333V4.667C0 3.747.746 3 1.667 3H2a1 1 0 011-1h1a1 1 0 011 1h.682A5.97 5.97 0 019 2c1.227 0 2.367.368 3.318 1M2 4.5a.5.5 0 10-1 0 .5.5 0 001 0M14 8A5 5 0 104 8a5 5 0 0010 0"/>',
    },
});
